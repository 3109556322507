import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <div class="m-md-3">
      <h1>About Hike Log</h1>
      <p>
        This page provides information on the intent behind this website, and
        how to use it.
      </p>
      <hr/>
      <h2>About me</h2>
      <p>
        to be filled with information about me.
      </p>
      <hr/>
      <h2>How to use this website</h2>
      <p>
        On the home page, you can select a walk on the map or on the table to
        view the detailed information page. The table can also be sorted using
        the following parameters:
        <dl>
          <dt>Distance: </dt>
          <dd>Total walk distance in kilometres (km)</dd>
          <dt>Ascent: </dt>
          <dd>Total up-hill walking for the whole route in metres</dd>
          <dt>Grade: </dt>
          <dd>
            I've decided to follow the walk difficulty definitions from one of
            my favourite walking websites -{" "}
            <a href="https://www.walkingenglishman.com/homepage.htm">
              The Walking Englishman
            </a>
            . He defines the following:
            <ul>
              <li>
                <strong>Easy: </strong>A straightforward walk with no
                difficulty. Less than 8 kilometres in length with less than 300
                metres of climbing.
              </li>
              <li>
                <strong>Moderate: </strong> A walk of 8 kilometres and over and less
                than 16 kilometres and/or 300 - 599 metres of climbing.
              </li>
              <li>
                <strong>Hard: </strong>A walk of 16 - 28.9 kilometres and/or 600 -
                999 metres of climbing.
              </li>
              <li>
                <strong>Strenuous: </strong>A walk of 29 kilometres or more and/or at
                least 1,000 metres of climbing.
              </li>
              <li>
                <strong>Technical: </strong>Any walk which requires exposed
                ridge walking, scrambling, water crossing, etc. These walks
                carry explanations in the report.
              </li>
            </ul>
          </dd>
        </dl>
      </p>
      <hr/>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
